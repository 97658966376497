import { inject } from 'vue';
import { subscribeKey, unsubscribeKey } from '@drapejs/core';

export default function (options) {
  const subscribe = inject(subscribeKey, () => Promise.resolve());
  const unsubscribe = inject(unsubscribeKey, () => Promise.resolve());

  const handler = (data) => {
    const products = data?.productsCarouselBlockTabProducts?.products || [];
    options.setProducts(products);
  };

  const registerProductBlockTab = (block, tabId) => {
    if (hasProductDataSource(block) && tabId) {
      subscribe(getProductsCarouselBlockCacheKey(block, tabId), handler, {
        immediate: true,
      });
    }
  };

  const unregisterProductBlockTab = (block, tabId) => {
    if (hasProductDataSource(block) && tabId) {
      unsubscribe(getProductsCarouselBlockCacheKey(block, tabId), handler);
    }
  };

  return {
    registerProductBlockTab,
    unregisterProductBlockTab,
  };
}

export function getProductsCarouselBlockCacheKey(block, tabId) {
  return `products:block:${block.systemId}${tabId}`;
}

export function hasProductDataSource(block) {
  return block?.fields?.DataSource;
}
